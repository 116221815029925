/**
 * класс ExtendEventEmitter - сильно упрощенный вариант EventEmitter-а из NodeJs
 */
class ExtendEventEmitter {
	/**
	 * This callback is displayed as part of the MyClass class.
	 * @callback ExtendEventEmitter~FuncCallback
	 * @param {any} arguments любые аргументы (функция примет те аргументы, которые были переданы методу {@link ExtendEventEmitter#emit ExtendEventEmitter.emit()} начиная со второго параметра)
	 */

	/**
	 * @constructor
	 * @param {boolean} isAsync - принимет значения true и false. По умолчанию false
	 */
	constructor(isAsync) {
		// Классический метод защиты от конструкторов, не вызываемых с помощью new
		if (!(this instanceof ExtendEventEmitter)) {
			return new ExtendEventEmitter(isAsync);
		}
		this.__isAsync = isAsync;
		this.__e = {};
	}
	/** @lends ExtendEventEmitter.prototype */
	/**
	 * Устанавливает постоянный обработчик `listener` события `eventName`
	 * @param  {String}   eventName    название события
	 * @param  {ExtendEventEmitter~FuncCallback} listener обработчик события
	 * @param  {object} context контекст, в котором будет вызван обработчик события
	 * 
	 * @returns {Function} установленный обработчик события
	 */
	on(eventName, listener, context) {
		if (typeof this.__e[eventName] !== 'object') {
			this.__e[eventName] = [];
		}
		if (!context) context = null;
		if (typeof listener !== 'function') return listener;

		this.__e[eventName].push({ listener: listener, context: context });
		return listener;
	}
	/**
	 * Удаляет обработчик listener события eventName
	 *
	 * @param  {String}   eventName    название события
	 * @param  {Function} listener обработчик события
	 */
	removeListener(eventName, listener) {
		const list = this.__e[eventName];

		if (!Array.isArray(list))
			return;

		const idx = list.findIndex(function (item) {
			return listener === item.listener;
		}, this);

		if (idx < 0)
			return;

		list.splice(idx, 1);

		if (!list.length)
			delete this.__e[eventName];
	}
	/**
	 * Создает событие event вызывая все зарегестрированные для него обработчики
	 *
	 * @param  {String}   eventName    название события
	 * @param  {any} arguments  аргументы для обработчиков событий
	 */
	emit(eventName, ...args) {
		// console.log('EVENT',eventName,args)
		const list = this.__e[eventName];
		if (!Array.isArray(list))
			return;
		if (this.__isAsync) {
			list.slice(0).forEach(function (item) {
				setImmediate(function () {
					item.listener.apply(item.context, args);
				});
			});
		} else {
			list.slice(0).forEach(function (item) {
				item.listener.apply(item.context, args);
			});
		}
	}
	/**
	 * Устанавливает одноразовый обработчик listener события eventName
	 *
	 * @param  {String}   eventName    название события
	 * @param  {ExtendEventEmitter~FuncCallback} listener обработчик события
	 * @param  {object} context контекст, в котором будет вызван обработчик события
	 * 
	 * @returns {Function} установленный обработчик события
	 */
	once(eventName, listener, context) {

		if (!context) context = null;
		const _self = this;
		const g = (...args) => {
			// console.log('removeListener', 1, eventName, (Array.isArray(_self.__e[eventName])?_self.__e[eventName].length:0))
			this.removeListener(eventName, g);
			listener.apply(context, args);
			// console.log('removeListener', 2, eventName, (Array.isArray(_self.__e[eventName])?_self.__e[eventName].length:0))
		};
		this.on(eventName, g);
		return listener;
	}
}

window.ExtendEventEmitter = ExtendEventEmitter;





// /**
//  * Тестовое событие
//  *
//  * @event ExtendEventEmitter#test
//  * @type {object}
//  * @property {object} test - просто параметр
//  * @property {boolean} test.isTest - просто параметр
//  * @param {object} test - просто параметр
//  * @param {boolean} test.isTest - просто параметр
//  * @param {string} test.test - просто параметр
//  */


export default ExtendEventEmitter;