import AnimationsManager from '../animator/class.animations.manager'
import ComponentView from './class.component.view'
import GameTimer from '../class.game.timer'


/**
 * Класс - фабрика компонентов
 */
class ComponentsFactory {
    /**
     * @constructor
     * @param {Phaser.scene|Phaser.game} scene ссылка на сцену
     */

    /**
     * @constructor
     * @param {Phaser.scene|Phaser.game} scene ссылка на сцену
     * @param {string} pathGame имя пути, откуда фабрика будет пытаться грузить ресурсы компонентов
     * @param {string} groupName название группы (если указанно, то фабрика загрузит конфиги и ресы только для компонентов этой группы )  
     */
    constructor(scene, pathGame, groupName, isJsTimer) {
        // console.log('ComponentsFactory.constructor');
        this.game = scene;
        this.scene = scene;
        this.pathGame = pathGame || this.game.pathGame || '/';
        if (!this.scene.load) this.scene = scene.engine;
        // this.targetComponentName = componentName;
        this.targetGroupName = groupName;

        this.cache = this.scene.cache;

        // this.config = config;
        this.managers = [];
        this.frames = {};
        this.components = [];
        this.componentsConfig = {};
        this.sounds = {};

        console.log(this.game);
        if (this.game.timer && !isJsTimer) {
            this.timer = this.game.timer;
        } else {
            this.timer = new GameTimer(this.game, isJsTimer);
        }



        // добавляем загруженные конфигурации
        if (this.targetGroupName) {
            // const config = configurations.getConfigByName(this.targetComponentName);
            // if (config)
            //     this.addComponentConfig(config);
            configurations.getConfigByTypeAndGroupName('component', this.targetGroupName).forEach(function (config) {
                // console.log("фабрика подключила конфиг:", config.type, config.name);
                this.addComponentConfig(config);
            }, this);

        } else {
            // console.log("конфиги:", configurations.list);
            configurations.getConfigByType('component').forEach(function (config) {
                // console.log("фабрика подключила конфиг:", config.type, config.name);
                this.addComponentConfig(config);
            }, this);

        }

        if (this.game.once && this.game.on && this.game.emit) {
            // ловим события стадий инициализации игры
            this.game.once('Game.constructor', () => {

            });
            this.game.once('Game.init', () => {

            });
            this.game.once('Game.preload', () => {
                this.preload();
            });
            this.game.once('Game.create', () => {
                this.create();
            });
            this.game.on('Game.update', () => {
                this.update();
            });
        }

    }
    /**
     * Добавляет в фабрику конфигурацию нового компонента. Добавление конфигураций необходимо
     * произвести до вызова метода {@link ComponentsFactory#preload ComponentsFactory.preload(...)}.
     * @param {object} config объект с конфигурацией нового компонента.
     * 
     * @returns {ComponentsFactory} ссылка на самого себя.
     */
    addComponentConfig(config) {
        if (!config.type || config.type !== 'component')
            throw 'Тип подключаемого конфигурациюнного файла не "component"';

        if (!config.name)
            throw 'У компонента не задано имя. Укажите его в конфигурационном файле компонента в свойстве name';

        if (this.componentsConfig[config.name])
            throw 'Cимвол c именем ' + config.name + ' уже загружен, дайте компоненту другое имя';

        if (!config.sprites)
            config.sprites = [];
        if (!config.audio)
            config.audio = [];
        if (!config.sounds)
            config.sounds = {};
        if (!config.compositions)
            config.compositions = {};
        // if(!config.animations) config.animations = {};
        if (!config.layers)
            config.layers = [];
        if (!config.presets)
            config.presets = [];
        if (!config.animators)
            config.animators = [];
        if (!config.events)
            config.events = [];

        config.manager = new AnimationsManager(this.scene);
        this.componentsConfig[config.name] = config;
        this.managers.push(config.manager);

        return this;
    }



    /**
     * Данный метод нужен для предзагрузки ресурсов указанных в подключенных конфигурациях компонентов
     * При использовании с Phaser этот метод необходимо вызвать в секции .preload сцены.
     * При этом, конфигурации компонентов должны быть добавленны с использованием метода {@link ComponentsFactory#addComponentConfig ComponentsFactory.addComponentConfig(...)}
     * до вызова данного метода {@link ComponentsFactory#preload ComponentsFactory.preload(...)}
     */
    preload() {
        // console.log('ComponentsFactory.preload');
        // this.ownername = 'mlogachev';
        // this.path = 'games/slots/sharedresources/coolfire2/';
        // this.pathGame = 'games/slots/' + this.ownername + '/coolfire2/test/';
        // const resourcesPath = this.game.pathGame||this.pathGame;
        const resourcesPath = this.pathGame;
        // const resourcesPath = this.game.config.gameResourcesPath;


        Object.values(this.componentsConfig).forEach(function (config) {
            config.sprites.forEach(function (item) {
                // загрузка спрайтов
                if ((!item.image && !item.spritesheet) || !item.name)
                    return;
                // console.log("!!! пытаюсь загрузить !!!",resourcesPath + item.image);
                if (item.atlas) {
                    // console.log(item.name)
                    this.scene.load.atlas(
                        item.name,
                        resourcesPath + item.image,
                        resourcesPath + item.atlas,
                        item.atlasData || Phaser.Loader.TEXTURE_ATLAS_JSON_HASH
                    );

                } else if (item.atlasBitmap) {
                    // console.log(item.name)
                    this.scene.load.bitmapFont(
                        item.name,
                        resourcesPath + item.image,
                        resourcesPath + item.atlasBitmap
                        // item.atlasData||Phaser.Loader.TEXTURE_ATLAS_XML_STARLING
                    );

                } else if (item.spritesheet) {
                    this.scene.load.spritesheet(
                        item.name,
                        resourcesPath + item.spritesheet,
                        item.frameWidth,
                        item.frameHeight,
                        ('number' === item.frameMax ? item.frameMax : -1),
                        ('number' === item.margin ? item.margin : 0),
                        ('number' === item.spacing ? item.spacing : 0),

                    );
                } else {
                    this.scene.load.image(
                        item.name,
                        resourcesPath + item.image
                    );
                }
            }, this);

            config.audio.forEach(function (item) {
                // загрузка спрайтов
                if (!item.sound || !item.name)
                    return;
                if (item.sound) {
                    this.scene.load.audio(item.name, resourcesPath + item.sound, true);
                }
            }, this);


            config.animators.forEach(function (item) {
                // инициализация аниматоров
                config.manager.addAnimator(
                    item.name,
                    item.handler
                );
            }, this);

            config.presets.forEach(function (item) {
                // инициализация пресетов(наборов) анимаций
                config.manager.addPreset(
                    item.name,
                    item.params
                );
            }, this);

        }, this);

    }

    // whiteFramesData(){
    //     const _self = this;
    //     this.whiteCount = this.whiteCount||0;
    //     console.log('!!!!!!!!!!!!!!! White fata for all frames', this.whiteCount);
    //     this.whiteCount++;
    //     // ожидаем (недолго) обработки фазером подгруженных спрайтов
    //     const isAllFramesReady = Object.values(this.componentsConfig).every(function (config) {
    //         return config.sprites
    //             .every(function (item) {

    //                 return false;//this.game.cache.hasFrameData(item.name);
    //             }, this);
    //     }, this);

    //     if(isAllFramesReady){
    //         console.log('!!!!!!!!!!!!!!! All frames data is READY')
    //     }else{
    //         if(this.whiteCount<5){
    //             setTimeout(function() {
    //                 _self.whiteFramesData();
    //             },1000);

    //         }else{
    //             console.log('!!!!!!!!!!!!!!! Lost fata for next frames');
    //         }
    //     }
    // }

    // !!! технический метод !!!
    // Производит поиск загруенных атласов по кэшу фазера и запоминает
    // для них соответсвие имен кадров их индексам.
    // @param {string} spriteName имя загруженного спрайта/картинки/атласа.
    findFramesData(spriteName) {
        if (!spriteName)
            return this;
        if (this.frames[spriteName])
            return this;


        this.spriteData = undefined;
        this.scene.cache._cacheMap.forEach(function (item) {
            if (!item)
                return;
            const data = item[spriteName];
            if (!data)
                return;
            if (this.frames[data.key])
                return;
            if (!data.frameData)
                return;
            if (!data.frameData._frameNames)
                return;
            const result = {};
            Object.entries(data.frameData._frameNames).forEach(function (item) {
                result[item[0]] = item[1];
            }, this);
            if (Object.keys(result).length === 0)
                return false;
            this.frames[data.key] = result;
            // console.log(2, result);
            return;
        }, this);
        // const a = this.game.cache.getJSON(spriteName, true);
        // const b = this.frames[spriteName];
        // console.log(spriteName, a, b);
        return this;
    }
    /**
     * Данный метод нужен для инициализации звуковых ресурсов указанных в подключенных конфигурациях компонентов
     * При использовании с Phaser этот метод необходимо вызвать в секции .create сцены еще до создания фабрикой
     * первых компонентов
     */
    create() {
        // console.log('ComponentsFactory.create');
        const self = this;

        // при подгрузке новых компонетов с аудиоресурсами добавляем их в очередь на декодирование
        Object.values(this.componentsConfig).forEach(function (config) {
            config.audio.forEach(function (item) {
                // подключение звуков
                this.sounds[item.name] = this.scene.add.audio(item.name);
            }, this);
        }, this);

        // this.whiteFramesData();

        // ожидаем (недолго) обработки фазером подгруженных спрайтов
        Object.values(this.componentsConfig).forEach(function (config) {
            config.sprites
                .forEach(function (item) {
                    // ох ка же это не хорошо. думаю как избавится
                    // по факту тут мы ждем когда фазер в кэше индексы 
                    // атласа обновит
                    // const time = Date.now() + 100;
                    // while (time > Date.now() && !this.frames[item.name]) {
                    this.findFramesData(item.name);
                    // }
                    // if (!this.frames[item.name]) {
                    //     console.warn('Не удалось загрузить спрайт "' + item.name + '->' + this.pathGame+item.image + '" указанный в компоненте: "' + config.name + '"');
                    // }
                }, this);
        }, this);

    }


    /**
     * данный метод нужен для правильной отработки встроенного менеджера анимаций
     * При использовании с Phaser этот метод необходимо вызвать в секции .update
     */
    update() {
        this.managers.forEach(function (manager) {
            manager.update();
        }, this);

        if(!this.game.timer && typeof this.game.on !== 'function')
            this.timer.update();
    }


    /**
     * Функция обратного вызова, передаваемая в метод {@link ComponentsFactory#setTimer ComponentsFactory.setTimer(...)} будет вызвана по истечении
     * таймаута по времени Phaser-а.
     * @callback ComponentsFactory~FuncCallback
     */

    /**
     * Работа почти метода идентична работе функции setTimeout(...). Разница заключается
     * лишь в том, что когда Phaser находится на паузе, таймеры, установленные этим методом
     * тоже встают на паузу.
     * @param {ComponentsFactory~FuncCallback} callback функция обратного вызоа. Будет вызвана по истечении
     * таймаута по времени Phaser-а.
     * @param {number} time Время задержки в милисекундах.
     * @param {object} context Контекст, в котором будет вызвана функция обратного вызоа.
     * 
     * @returns {ComponentsFactory} ссылка на самого себя.
     */
    setTimer(callback, time, context) {
        this.timer.setTimer(callback, time, context);
        return this;
    }

    /**
     * Создает новый объект класса ComponentView с конфигурацией, имеющей название name.
     * @param {string} name название конфигурации многослойного анимированного компонента.
     * 
     * @returns {ComponentsView} объект компонента.
     */
    getComponent(name) {
        // console.log('ComponentsFactory.getComponent 1', name, this.componentsConfig, this.componentsConfig[name]);

        const config = this.componentsConfig[name];
        if (!config)
            throw 'Не найдена конфигурация для "' + name + '". Добавить ее вы можете в секции GameClient.preload командой ComponentsFactory.addComponentConfig(name, config)';

        config.sounds = this.sounds;
        const component = new ComponentView(this.scene, this, config);
        this.components.push(component);
        if (config.startComposition) {

            // true тут стоит, чтобы когда компоненты (у которых втавлен как слой
            // другие компоненты) запускали свою анимацию, то не сбрасывали стартовую
            // анимацию своих субкомпонентов
            component.play(config.startComposition, true);
        };
        return component;
    }

    /**
     * Метод уничтожает текущий инстанс фабрики, а так же все созданные данной фабрикой
     * инстансы компонентов и все их внутренние объекты (группы, спрайты, менеджеры анимаций).
     */
    destroy() {
        this.manager.stop();
        this.manager.destroy();
        this.components.forEach(function (component) {
            component.destroy();
        });
    }
}
window.ComponentsFactory = ComponentsFactory;

export default ComponentsFactory;
