import Configurations from './class.configurations'
import ComponentsFactory from './components/class.components.factory'
import ExtendEventEmitter from './class.extend.event.emitter'

console.log('class.components.js');

// export default ComponentsFactory;



